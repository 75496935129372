<template>
  <div>
    <CRow>
      <CCol xs="12" lg="12">
        <CCard>
          <CCardHeader>
            <div class="row">
              <CCol sm="6" class="align-self-center">
                <strong>{{ $lang.user.crud.create }}</strong>
              </CCol>
            </div>
          </CCardHeader>
          <CCardBody>
            <CAlert
                :color="messageColor"
                closeButton
                :show.sync="dismissCountDown"
                fade
            >
              {{ err_msg }}
            </CAlert>
            <CForm @submit.prevent="onProfileSubmit">
              <CRow>
                <CCol md="6">
                  <div
                      class="form-group"
                      :class="{ 'form-group--error': $v.user.name.$error }"
                  >
                    <label class="form__label"
                    >{{ $lang.user.form.name }}
                      <required_span/>
                    </label>
                    <input
                        type="text"
                        :maxlength="maxlength.name"
                        :placeholder="$lang.user.form.name"
                        class="form-control"
                        v-model="user.name"
                    />
                    <small
                        class="error"
                        v-if="$v.user.name.$error && !$v.user.name.required"
                    >{{ $lang.user.validation.required.name }}</small
                    >
                  </div>
                </CCol>
                <CCol md="6">
                  <div
                      class="form-group"
                      :class="{ 'form-group--error': $v.user.email.$error }"
                  >
                    <label class="form__label"
                    >{{ $lang.user.form.email }}
                      <required_span/>
                    </label>
                    <input
                        :maxlength="maxlength.email"
                        type="text"
                        :placeholder="$lang.user.form.email"
                        class="form-control"
                        v-model="user.email"
                    />
                    <small
                        class="error"
                        v-if="$v.user.email.$error && !$v.user.email.required"
                    >{{ $lang.user.validation.required.email }}</small
                    >
                    <small
                        class="error"
                        v-if="$v.user.email.$error && !$v.user.email.email"
                    >{{ $lang.user.validation.email.email }}</small
                    >
                  </div>
                </CCol>
                <CCol md="6">
                  <div
                      class="form-group"
                      :class="{
                      'form-group--error': $v.user.mobileNumber.$error,
                    }"
                  >
                    <label class="form__label"
                    >{{ $lang.user.form.mobileNumber }}
                      <required_span
                      />
                    </label>
                    <!--                        <input type="text" :placeholder="$lang.user.form.mobileNumber" class="form-control" v-model="admin_profile.mobileNumber"/>-->
                    <vue-tel-input
                        v-model="user.mobileNumber"
                        v-on:country-changed="countryChanged"
                        v-bind="bindProps"
                    ></vue-tel-input>
                    <small
                        class="error"
                        v-if="
                        $v.user.mobileNumber.$error &&
                        !$v.user.mobileNumber.required
                      "
                    >{{ $lang.user.validation.required.mobileNumber }}</small
                    >
                  </div>
                </CCol>
                <CCol md="6">
                  <div
                      class="form-group"
                      :class="{ 'form-group--error': $v.user.address1.$error }"
                  >
                    <label class="form__label"
                    >{{ $lang.user.form.address1 }}
                      <required_span/>
                    </label>
                    <textarea
                        :maxlength="maxlength.address1"
                        :placeholder="$lang.user.form.address1"
                        class="form-control"
                        v-model="user.address1"
                    />

                    <small
                        class="error"
                        v-if="
                        $v.user.address1.$error && !$v.user.address1.required
                      "
                    >{{ $lang.user.validation.required.address1 }}</small
                    >
                  </div>
                </CCol>
                <CCol md="6">
                  <div class="form-group" :class="{ 'form-group--error': '' }">
                    <label class="form__label"
                    >{{ $lang.user.form.country }}
                      <required_span/>
                    </label>
                    <v-select
                        :options="countryOptions"
                        v-model="user.country"
                        @option:selected="loadState"
                    >
                    </v-select>
                    <small
                        class="error"
                        v-if="$v.user.country.$error && !$v.user.country.required"
                    >
                      {{ $lang.user.validation.required.country }}
                    </small>
                  </div>
                </CCol>

                <CCol md="6">
                  <div class="form-group" :class="{ 'form-group--error': '' }">
                    <label class="form__label">{{ $lang.user.form.timezone }}
                      <!--                      <required_span />-->
                    </label>
                    <v-select
                        :options="timezoneOptions"
                        v-model="user.timezone"
                    >
                    </v-select>
                  </div>
                </CCol>


                <CCol md="6">
                  <div
                      class="form-group"
                      :class="{ 'form-group--error': $v.user.state.$error }"
                  >
                    <label class="form__label"
                    >{{ $lang.user.form.state }}
                      <required_span/>
                    </label>
                    <v-select
                        :options="StateOptions"
                        v-model="user.state"
                        @option:selected="loadCity"
                    >
                    </v-select>
                    <small
                        class="error"
                        v-if="$v.user.state.$error && !$v.user.state.required"
                    >{{ $lang.user.validation.required.state }}</small
                    >
                  </div>
                </CCol>
                <CCol md="6">
                  <div
                      class="form-group"
                      :class="{ 'form-group--error': $v.user.city.$error }"
                  >
                    <label class="form__label"
                    >{{ $lang.user.form.city }}
                      <required_span/>
                    </label>
                    <v-select :options="cityOptions" v-model="user.city">
                    </v-select>
                    <small
                        class="error"
                        v-if="$v.user.city.$error && !$v.user.city.required"
                    >{{ $lang.user.validation.required.city }}</small
                    >
                  </div>
                </CCol>
                <CCol md="6">
                  <div class="form-group" :class="{ 'form-group--error': '' }">
                    <label class="form__label"
                    >{{ $lang.user.form.role }}
                      <required_span/>
                    </label>
                    <v-select :options="roleOptions" v-model="user.role">
                    </v-select>
                    <small
                        class="error"
                        v-if="$v.user.role.$error && !$v.user.role.required"
                    >
                      {{ $lang.user.validation.required.role }}
                    </small>
                  </div>
                </CCol>
              </CRow>
              <CRow>
                <CCol md="12">
                  <div class="form-group" :class="{ 'form-group--error': '' }">
                    <img :src="SRCIMAGE" v-if="SRCIMAGE" alt="Profile Image"/>
                    <ImageCropper @profileImage="getProfile($event)"/>
                  </div>
                </CCol>
              </CRow>
              <CRow>
                <CCol col="6" class="text-left">
                  <CButton
                      color="primary"
                      :disabled="submitted"
                      class="px-4"
                      type="submit"
                  >
                    {{ $lang.common.general.submit }}
                  </CButton>
                </CCol>
              </CRow>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {
  staff,
  role,
  country,
  state,
  city,
  timezones
} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import {ServerTable} from "vue-tables-2";
import VueSweetalert2 from "vue-sweetalert2";
import {
  required,
  email,
} from "vuelidate/lib/validators";
import required_span from "../../components/layouts/general/required-span";
import ImageCropper from "../ImageCropper";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import {Maxlength} from "../../store/maxlength";

Vue.use(VueTelInput);
Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);

export default {
  name: "Profile",
  mixins: [Mixin],
  components: {
    required_span,
    ImageCropper,
  },
  data() {
    return {
      bindProps: {
        mode: "international",
        placeholder: "Contact No.",
        defaultCountry: "IN",
        dropdownOptions: {
          showFlags: true,
        },
        inputOptions: {
          maxlength: Maxlength.user.mobile,
        },
        validCharactersOnly: true,
      },
      messageColor: "",
      submitted: false,
      SubmitBtn: "Submit",
      roleOptions: [],
      // defaultImage: require("@/assets/img/defaultImage.jpg"),
      FILE: null,
      imgSrc: null,
      SRCIMAGE: null,
      existImage: false,
      imageURL: null,
      imageRemove: null,
      imageOutput: "",
      saving: false,
      saved: false,
      adminId: "",
      err_msg: "",
      largeModal: false,
      submitType: "",
      alertMessage: "test",
      showAlert: false,
      dismissCountDown: 0,
      self: this,
      msg: "",
      items: [],
      countryOptions: [],
      StateOptions: [],
      cityOptions: [],
      timezoneOptions: [],
      activePage: 1,
      module: staff,
      moduleRole: role,
      moduleCountry: country,
      moduleState: state,
      moduleCity: city,
      moduleTimezone: timezones,
      avatar: {
        imageURl: "",
      },
      user: {
        name: "",
        // username: '',
        email: "",
        imageURL: "",
        role: "",
        address1: "",
        country: "",
        state: "",
        city: "",
        mobileNumber: "",
        mobileNumberCode: "",
        timezone: "",
      },
      maxlength: {
        name: Maxlength.user.name,
        email: Maxlength.user.email,
        address1: Maxlength.user.address1,
      },
    };
  },
  validations: {
    user: {
      name: {
        required,
      },
      // username: {
      //     required
      // },
      role: {
        required,
      },
      email: {
        required,
        email,
      },
      country: {
        required,
      },
      state: {
        required,
      },
      city: {
        required,
      },
      address1: {
        required,
      },
      mobileNumber: {
        required,
      },
    },
  },
  mounted() {
    let self = this;
    store.commit("showLoader", false); // Loader Off
    self.dismissCountDown = 0;
    this.$root.$on("alert", (arg1, arg2) => {
    });
    if (localStorage.getItem("showAlert") !== "") {
      this.setAlert(true);
    }
    this.setAlert(false);
    localStorage.setItem("showAlert", "");

    axios.get(this.getRoleUrlApi(this.moduleRole)).then((response) => {
      response.data.data.map(function (value, key) {
        self.roleOptions.push({value: value.id, label: value.name});
      });
    });
    axios.get(this.listUrlApi(this.moduleCountry)).then((response) => {
      response.data.data.map(function (value, key) {
        self.countryOptions.push({value: value.id, label: value.name});
      });
    });
    axios.get(this.listUrlApi(this.moduleTimezone)).then((response) => {
      response.data.data.map(function (value, key) {
        self.timezoneOptions.push({value: value.timezone, label: value.timezone});
      });
    });
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
    avatar: {
      handler: function () {
        this.saved = false;
      },
      deep: true,
    },
  },
  methods: {
    countryChanged(country) {
      let self = this;
      self.user.mobileNumberCode = "+" + country.dialCode;
      let mobile = self.user.mobileNumber;
      self.user.mobileNumber = mobile
          .replace("+" + country.dialCode, "")
          .trim();
    },

    loadState() {
      let self = this;
      const postData = {countryId: self.user.country.value};
      self.user.state = {}; //reset
      axios
          .post(this.listUrlApi(this.moduleState), postData)
          .then((response) => {
            self.StateOptions = [];
            response.data.data.map(function (value, key) {
              self.StateOptions.push({value: value.id, label: value.name});
            });
          });
    },
    loadCity() {
      let self = this;
      const postData = {stateId: self.user.state.value};
      self.user.city = {}; //reset
      axios
          .post(this.listUrlApi(this.moduleCity), postData)
          .then((response) => {
            self.cityOptions = [];
            response.data.data.map(function (value, key) {
              self.cityOptions.push({value: value.id, label: value.name});
            });
          });
    },
    getProfile(profile) {
      let self = this;
      self.user.imageURL = profile;
      if (profile === "") {
        self.user.imageRemove = 1;
      } else {
        self.user.imageRemove = 0;
      }
    },
    onFileUpload(event) {
      this.FILE = event.target.files[0];
    },
    onProfileSubmit() {
      let self = this;
      this.$v.user.$touch();
      if (this.$v.user.$invalid) {
        self.submitted = false; //Enable Button
        this.submitStatus = "ERROR";
      } else {
        let self = this;
        self.submitted = true; //Disable Button
        const formData = new FormData();
        formData.append("image", self.user.imageURL);
        formData.append("name", self.user.name);
        formData.append("email", self.user.email);
        formData.append("role", self.user.role.value);
        formData.append("address1", self.user.address1);
        formData.append("country", self.user.country.value);
        formData.append("timezone", self.user.timezone.value);
        formData.append("state", self.user.state.value);
        formData.append("city", self.user.city.value);
        formData.append("mobileNumber", self.user.mobileNumber);
        formData.append("mobileNumberCode", self.user.mobileNumberCode);
        axios
            .post(this.createUrlWeb(this.module), formData)
            .then((response) => {
              self.submitted = false; //Enable Button
              store.commit("showLoader", false); // Loader Off
              if (response.data.code === 200) {
                self.err_msg = response.data.message;
                localStorage.setItem("notificationType", "success");
                localStorage.setItem(
                    "notificationMessage",
                    response.data.message
                );
                // store.commit("FLASH_NOTIFICATION");
                this.$router.push({name: "Staff"});
                self.dismissCountDown = 10;
                self.messageColor = "success";
              } else {
                self.err_msg = response.data.message;
                self.messageColor = "danger";
                self.dismissCountDown = 10;
              }
            }).catch(function (error) {
          let data;
          if (error.response.data.error) {
            data = error.response.data.error.toString();
          } else {
            data = error.response.data.message;
          }
          self.err_msg = data;
          self.messageColor = "danger";
          self.dismissCountDown = 10;
          self.submitted = false; //Enable Button
        });
      }
    },
    setAlert(value) {
      this.showAlert = value;
    },
    createTableRow() {
      this.$router.push({name: "Profile Create"});
    },
    rowClicked(item, index) {
      this.$router.push({path: `country/view/${item}`});
    },
    pageChange(val) {
      this.$router.push({query: {page: val}});
    },
    loadData() {
      let self = this;
      axios
          .get(this.listUrlApi(this.module), self.model)
          .then(function (response) {
            if (response) {
              self.data = response.data;
            } else {
              self.data = [];
            }
          });
    },
  },
};
</script>
<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
